import { Refresh, VolumeOff, VolumeUp } from '@mui/icons-material';
import { Checkbox, Stack } from '@mui/material';
import Button from '@mui/material/Button';
import React, { useContext, useEffect, useState } from 'react';
import myFunctions from '../../../../myFunctions';
import { BuisListContext } from '../../../MyContexts/BuisList_Context';
import { TheMasterContext } from '../../../MyContexts/Master_Context';
import { UserContext } from '../../../MyContexts/Users_Context';
import BetweenDates from '../../../MySections/TimeSections/BetweenDates';
import BuisSelect from './BuisSelect';




const BuisTollBar = (props) => {
    const [user] = useContext(UserContext);
    const [buisLst, setBuisLst] = useContext(BuisListContext);
    const [theMaster] = useContext(TheMasterContext);

    const [soundCheckd, setSoundCheckd] = useState(props.soundOn);

    useEffect(() => {
        setSoundCheckd(props.soundOn)
    }, [props.soundOn])



    const refreshTheList = (selectedBtn) => {
        props.refreshTheList(selectedBtn)
    }

    const soundChanghed = () => {

        props.setSoundOn(!soundCheckd);
    }

    return (
        <div className={"buisTollBar"}>
            <div className={"refAndSound"}>
                <Checkbox
                    icon={<Refresh sx={{ color: 'white' }} />}
                    checkedIcon={<Refresh sx={{ color: 'white' }} />}
                    sx={{}}
                    className={"RefreshBtn"}
                    onClick={() => {
                        refreshTheList(props.selectedBtn);
                    }}
                />
                <Checkbox
                    value={soundCheckd}
                    checked={soundCheckd}
                    onChange={() => { soundChanghed() }}
                    checkedIcon={<VolumeUp sx={{ color: 'white' }} />}
                    icon={<VolumeOff sx={{ color: 'white' }} />}
                    sx={{}}
                    className={"soundONOF"}
                />
            </div>
            {/* 
            <Refresh sx={{ color: 'white' }}
                onClick={() => {
                    refreshTheList(props.selectedBtn);
                }} /> */}

            {
                (user.permiss === "admin") ?
                    <BuisSelect busissList={buisLst}
                        defaultValue={myFunctions.getBuisNameByIdInList(buisLst, props.userBuis)}
                        firstHead={"כל העסקים"}
                        clss={"buisSelectOnTBAR onLeft"}
                        userSelection={props.userBuis ? props.userBuis : -1}
                        setUserBuis={(newVal) => { props.setUserBuis(newVal) }}
                    />
                    : ""
            }

            {(theMaster.blockDeliverys?.block) ? <div className={"errorLine"}><p>מנהל המערכת חסם את המשלוחים לכלל העסקים</p></div> : ""}
            {(theMaster.blockPickUps?.block) ? <div className={"errorLine"}><p>מנהל המערכת חסם את האיסופים לכלל העסקים</p></div> : ""}


            {
                (user.permiss === "admin") &&
                <>
                    {(theMaster.timingDeliverys
                         && theMaster.timingDeliverys > 0) ? <div className={"errorLine"}><p>{`יש הגדרת זמן משלוח למינימום ${theMaster.timingDeliverys} דק' לכלל העסקים`}</p></div> : ""}
                    {(theMaster.minTimingDeliverys && theMaster.minTimingDeliverys > 0) ? <div className={"errorLine"}><p>{`יש תוספת זמן משלוח של ${theMaster.minTimingDeliverys} דק' לכלל העסקים`}</p></div> : ""}
                </>
            }

            <Stack direction="row" spacing={2}>
                <div className={"btnMnuTop onRight"}>
                    <div className="spaceButtomBig ms-form-group my-0 mb-0 has-icon fs-14">
                        <input type="search" className="dtSearchInput ms-form-input" id="dtSearchInput" name="dtSearchInput"
                            placeholder="מספר הזמנה, טלפון או שם לקוח" value={props.srchText} onChange={(e) => props.setSrchText(e.target.value)}
                            onKeyUp={(e) => props.checkIfEnter(e)}
                        />
                        <Button className="dtSearchIcon flaticon-search text-disabled" onClick={() => props.searchOrder()} />
                    </div>

                    <Button className={`btnBigMnu ${props.selectedBtn.toString() === "6" ? "selectedMnuBtn" : ""}`} variant="contained" onClick={() => refreshTheList(6)}>
                        פתוחות היום
                    </Button>
                    <Button className={`btnBigMnu ${props.selectedBtn.toString() === "7" ? "selectedMnuBtn" : ""}`} variant="contained" onClick={() => refreshTheList(7)}>
                        הכל מהיום
                    </Button>

                    <Button className={`btnBigMnu ${props.selectedBtn.toString() === "8" ? "selectedMnuBtn" : ""}`} variant="contained" onClick={() => refreshTheList(8)}>
                        אתמול
                    </Button>

                    <Button className={`btnBigMnu ${props.selectedBtn.toString() === "9" ? "selectedMnuBtn" : ""}`} variant="contained" onClick={() => refreshTheList(9)}>
                        מתחילת החודש
                    </Button>
                    <Button className={`btnBigMnu ${props.selectedBtn.toString() === "10" ? "selectedMnuBtn" : ""}`}
                        variant="contained" onClick={() => { props.setShowBetweenDts(!props.showBetweenDts); refreshTheList(10) }}>
                        לפי תאריכים
                    </Button>
                    <Button className={`btnBigMnu ${props.selectedBtn.toString() === "12" ? "selectedMnuBtn" : ""}`} variant="contained" onClick={() => props.showAllOpen(12)}>
                        פתוחות הכל
                    </Button>

                    <div className={`spaceTop ${(props.showBetweenDts) ? "" : "hideMe"}`}>
                        <div className={"onRight"}>
                            {(props.showBetweenDts) ? <BetweenDates
                                startDTS={props.startDTS} setStartDTS={(dt) => props.setStartDTS(dt)}
                                endDTS={props.endDTS} setEndDTS={(dt) => props.setEndDTS(dt)} /> : ""}
                        </div>
                        <div className={"onRight spaceTopBig"}>
                            <Button className={`btnBigMnu onRight
                                         ${props.selectedBtn.toString() === "11" ? "selectedMnuBtn" : ""}`} variant="contained" onClick={() => refreshTheList(11)}>
                                חפש
                            </Button></div>
                    </div>



                </div>
                {/* <div className={"btnMnuTop onLeft"}>
            <Link to={`/Order`}>
                <Button className={"btnAddItm"} variant="contained"
                    startIcon={<AddBoxOutlined style={{ color: 'white' }} />}>
                    חדש
                </Button>
            </Link>
        </div> */}
            </Stack>

        </div >
    )
}

export default BuisTollBar;

