import React, { useContext, useEffect, useState } from 'react'
import { RestContext } from "../../../../../MyContexts/Rest_Context";
import Button from '@mui/material/Button';
import { Accordion, Card } from 'react-bootstrap';
import EraseCheck from '../../../../../MySections/PopUps/EraseCheck';
import { AddBoxOutlined } from '@mui/icons-material';
import InputDubleSet from '../../../../../MySections/PopUps/InputDubleSet';
import utilsSilo from '../../../../../../MyConns/_Utils/utilsSilo';
import AccordProdTextArea from './AccordProdTextArea';
import AccordProdTextInput from './AccordProdTextInput';
import AccordProdNumInput from './AccordProdNumInput';
import SucessToast from '../../../../../MySections/Toasts/SucessToast';


const AccordProdOptions = (props) => {

    const [rest, setRest] = useContext(RestContext);
    const [buisMenu, setBuisMenu] = useState(rest.buisMenu);
    const [thePopUp, setThePopUp] = useState("");

    let optis = "";
    let index = props.index;
    let proIndex = props.proIndex;
    let OptINdex = props.OptINdex;
    let prodOpt = props.prodOpt;

    const changeProdOptionName = (divIndex, prodIndex, OptINdex, newValue) => {

        let tempMnu = [...buisMenu];
        tempMnu[divIndex].Products[prodIndex].prodOptions[OptINdex].optionName = newValue;

        setBuisMenu(tempMnu);
        rest["buisMenu"] = tempMnu;
        // props.UpdateTheBuis(tempMnu);
    }


    const changeProdOptionBtnTxt = (divIndex, prodIndex, OptINdex, newValue) => {

        let tempMnu = [...buisMenu];
        tempMnu[divIndex].Products[prodIndex].prodOptions[OptINdex].optionBtnTxt = newValue;

        setBuisMenu(tempMnu);
        rest["buisMenu"] = tempMnu;
        // props.UpdateTheBuis(tempMnu);
    }
    const changeProdOptionMaxSelection = (divIndex, prodIndex, OptINdex, newValue) => {

        let tempMnu = [...buisMenu];

        tempMnu[divIndex].Products[prodIndex].prodOptions[OptINdex].maxSelection = newValue;

        setBuisMenu(tempMnu);
        rest["buisMenu"] = tempMnu;
        // props.UpdateTheBuis(tempMnu);
    }
    const changePopOptionItmsEffected = (divIndex, prodIndex, OptINdex, newValue) => {

        let tempMnu = [...buisMenu];
        tempMnu[divIndex].Products[prodIndex].prodOptions[OptINdex].itemsEffected = newValue;

        setBuisMenu(tempMnu);
        rest["buisMenu"] = tempMnu;
        // props.UpdateTheBuis(tempMnu);
    }

    const changeProdOptionSplitSelection = (divIndex, prodIndex, OptINdex, newValue) => {

        let tempMnu = [...buisMenu];
        tempMnu[divIndex].Products[prodIndex].prodOptions[OptINdex].splitSelection = newValue;

        setBuisMenu(tempMnu);
        rest["buisMenu"] = tempMnu;
        // props.UpdateTheBuis(tempMnu);
    }
    const changeProdOptionSplitSelectionQurters = (divIndex, prodIndex, OptINdex, newValue) => {

        let tempMnu = [...buisMenu];
        tempMnu[divIndex].Products[prodIndex].prodOptions[OptINdex].spSelQurts = newValue;

        setBuisMenu(tempMnu);
        rest["buisMenu"] = tempMnu;
        // props.UpdateTheBuis(tempMnu);
    }



    const changeProdOptionAllowMulti = (divIndex, prodIndex, OptINdex, newValue) => {

        let tempMnu = [...buisMenu];
        tempMnu[divIndex].Products[prodIndex].prodOptions[OptINdex].allowMulti = newValue;

        setBuisMenu(tempMnu);
        rest["buisMenu"] = tempMnu;
        // props.UpdateTheBuis(tempMnu);
    }

    const changeProdOptionMustSelect = (divIndex, prodIndex, OptINdex, newValue) => {

        let tempMnu = [...buisMenu];
        tempMnu[divIndex].Products[prodIndex].prodOptions[OptINdex].mustSelect = newValue;

        setBuisMenu(tempMnu);
        rest["buisMenu"] = tempMnu;
        // props.UpdateTheBuis(tempMnu);
    }

    const changeChkSauces = (divIndex, prodIndex, OptINdex, newValue) => {

        let tempMnu = [...buisMenu];
        tempMnu[divIndex].Products[prodIndex].prodOptions[OptINdex].chkSauces = newValue;

        setBuisMenu(tempMnu);
        rest["buisMenu"] = tempMnu;
        // props.UpdateTheBuis(tempMnu);

    }

    const changeChkSideBySide = (divIndex, prodIndex, OptINdex, newValue) => {

        let tempMnu = [...buisMenu];
        tempMnu[divIndex].Products[prodIndex].prodOptions[OptINdex].chkSideBySide = newValue;

        setBuisMenu(tempMnu);
        rest["buisMenu"] = tempMnu;
        // props.UpdateTheBuis(tempMnu);

    }

    const changeChkMoreThenOne = (divIndex, prodIndex, OptINdex, newValue) => {

        let tempMnu = [...buisMenu];
        tempMnu[divIndex].Products[prodIndex].prodOptions[OptINdex].chkMoreThenOne = newValue;

        setBuisMenu(tempMnu);
        rest["buisMenu"] = tempMnu;
        // props.UpdateTheBuis(tempMnu);

    }


    const changeProdOptDetPrice = (divIndex, prodIndex, OptINdex, subOptsIndex, newValue) => {

        let tempMnu = [...buisMenu];
        tempMnu[divIndex].Products[prodIndex].prodOptions[OptINdex].options[subOptsIndex].addToPrice = newValue;

        setBuisMenu(tempMnu);
        rest["buisMenu"] = tempMnu;
        // props.UpdateTheBuis(tempMnu);
    }


    const changeProdOptDetMakat = (divIndex, prodIndex, OptINdex, subOptsIndex, newValue) => {

        let tempMnu = [...buisMenu];
        tempMnu[divIndex].Products[prodIndex].prodOptions[OptINdex].options[subOptsIndex].Makat = newValue;

        setBuisMenu(tempMnu);
        rest["buisMenu"] = tempMnu;
        // props.UpdateTheBuis(tempMnu);

    }

    const changeProdOptSauces = (divIndex, prodIndex, OptINdex, subOptsIndex, newValue) => {

        let tempMnu = [...buisMenu];
        tempMnu[divIndex].Products[prodIndex].prodOptions[OptINdex].options[subOptsIndex].withSauces = newValue;

        setBuisMenu(tempMnu);
        rest["buisMenu"] = tempMnu;
        // props.UpdateTheBuis(tempMnu);
    }

    const freeAdds = (divIndex, prodIndex, OptINdex, newValue) => {

        let tempMnu = [...buisMenu];
        tempMnu[divIndex].Products[prodIndex].prodOptions[OptINdex].freeAdds = newValue;

        setBuisMenu(tempMnu);
        rest["buisMenu"] = tempMnu;
        // props.UpdateTheBuis(tempMnu);

    }

    const saveToSilo = async (diviIndex, prodIndex, OptINdex) => {
        let tempMnu = [...buisMenu];
        let itmToSave = tempMnu[diviIndex].Products[prodIndex].prodOptions[OptINdex];
        let rstLst = await utilsSilo.createSilo(itmToSave);
        SucessToast({ position: "toast-bottom-right", text: "נשמר בהצלחה!" });
    }

    const removeDetOpti = (diviIndex, prodIndex, OptINdex, optsDetIndex) => {
        setThePopUp(<EraseCheck title={'האם בטוח למחוק את האופציה?'} sucecssFunc={() => removeDetOption(diviIndex, prodIndex, OptINdex, optsDetIndex)} />)
    }

    const removeDetOption = (diviIndex, prodIndex, OptINdex, optsDetIndex) => {
        let tempMnu = [...buisMenu];
        tempMnu[diviIndex].Products[prodIndex].prodOptions[OptINdex].options.splice(optsDetIndex, 1);
        setBuisMenu(tempMnu);
        props.UpdateTheBuis(tempMnu);
    }

    const removeOpti = (diviIndex, prodIndex, OptINdex) => {
        setThePopUp(<EraseCheck title={'האם בטוח למחוק את הנלווה?'} sucecssFunc={() => removeOptio(diviIndex, prodIndex, OptINdex)} />)
    }

    const removeOptio = (diviIndex, prodIndex, OptINdex) => {
        let tempMnu = [...buisMenu];
        tempMnu[diviIndex].Products[prodIndex].prodOptions.splice(OptINdex, 1);
        // setBuisMenu(tempMnu);
        props.UpdateTheBuis(tempMnu);
    }


    const createNewDetOption = (divindex, prodIndex, OptINdex) => {
        setThePopUp(
            <InputDubleSet Title={"הוספת אופציה"}
                ContentText={""} inLabel={"שם האופציה"} inLabel2={"תוספת מחיר"}
                okClick={(textBack) => createNewDetOptionOk(textBack, divindex, prodIndex, OptINdex)}
                cancelClick={() => setThePopUp()}
                errorMsg={"על מנת ליצור אופציה חדשה, חובה לתת לה שם."}
            />)
    }

    const createNewDetOptionOk = (textBack, divindex, prodIndex, OptINdex) => {

        let newDetOpt = {
            servType: textBack.inputTxt,
            addToPrice: textBack.inputPrc,
            Makat: "",
        };

        let tempMnu = [...buisMenu];
        tempMnu[divindex].Products[prodIndex].prodOptions[OptINdex].options.push(newDetOpt);
        setBuisMenu(tempMnu);
        props.UpdateTheBuis(tempMnu);
    }


    const changeProdOptDetName = (divIndex, prodIndex, OptINdex, subOptsIndex, newValue) => {

        let tempMnu = [...buisMenu];
        tempMnu[divIndex].Products[prodIndex].prodOptions[OptINdex].options[subOptsIndex].servType = newValue;

        setBuisMenu(tempMnu);


        // props.UpdateTheBuis(tempMnu);
    }


    const setUpOpti = (index, proIndex, OptINdex) => {

        let tempMnu = [...buisMenu];

        let temp = array_move([...tempMnu[index].Products[proIndex].prodOptions], OptINdex, OptINdex - 1);

        tempMnu[index].Products[proIndex].prodOptions = temp;

        setBuisMenu(tempMnu);
        props.UpdateTheBuis(tempMnu);

    }

    const setDownOpti = (index, proIndex, OptINdex) => {

        let tempMnu = [...buisMenu];
        let temp = array_move([...tempMnu[index].Products[proIndex].prodOptions], OptINdex, OptINdex + 1);

        tempMnu[index].Products[proIndex].prodOptions = temp;

        setBuisMenu(tempMnu);
        props.UpdateTheBuis(tempMnu);

    }


    const array_move = (arr, old_index, new_index) => {
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr;
    };




    return (
        <Card>
            <Accordion.Collapse className={`collapseparent`} eventKey={props.addsCounter}>
                <Card.Body>
                    <div className={"prodOpts"}>
                        <div className={"col-md-12 mb-2 overflowHid"}>

                            {prodOpt.isPopOption ?
                                <>
                                    <div className={"col-md-6 mb-2 onRight"}>
                                        <AccordProdTextInput headI={"כותרת החוצץ"} index={index} proIndex={proIndex} prodTxtVal={prodOpt.optionName}
                                            setProdItm={(index, proIndex, newValue) => changeProdOptionName(index, proIndex, OptINdex, newValue)} />

                                        <AccordProdNumInput headI={"מספר נלווים מושפעים"} minimum={0} index={index} proIndex={proIndex}
                                            prodTxtVal={prodOpt.itemsEffected ? prodOpt.itemsEffected : 0}
                                            setProdItm={(index, proIndex, newValue) => changePopOptionItmsEffected(index, proIndex, OptINdex, newValue)} />
                                        
                                        <AccordProdNumInput headI={"מקס' לבחירה"} minimum={0} index={index} proIndex={proIndex}
                                            prodTxtVal={prodOpt.maxSelection ? prodOpt.maxSelection : 0}
                                            setProdItm={(index, proIndex, newValue) => changeProdOptionMaxSelection(index, proIndex, OptINdex, newValue)} />
                                    </div>
                                    <div className={"col-md-6 mb-2 onRight"}>
                                        <div className={"input-group prodSwitch switchMe onRight"}>
                                            <label className={"spHead"}>בחירת חובה</label>
                                            <label className="ms-switch">
                                                <input type="checkbox" checked={prodOpt.mustSelect}
                                                    onChange={(e) => changeProdOptionMustSelect(index, proIndex, OptINdex, !prodOpt.mustSelect)} />
                                                <span className="ms-switch-slider ms-switch-primary round" />
                                            </label>
                                        </div>
                                        <div className={"input-group prodSwitch switchMe onRight"}>
                                            <label className={"spHead"}>2 בשורה</label>
                                            <label className="ms-switch">
                                                <input type="checkbox" checked={prodOpt.chkSideBySide}
                                                    onChange={(e) => changeChkSideBySide(index, proIndex, OptINdex, !prodOpt.chkSideBySide)} />
                                                <span className="ms-switch-slider ms-switch-primary round" />
                                            </label>
                                        </div>

                                    </div>
                                </>
                                :
                                <>
                                    <div className={"col-md-6 mb-2 onRight"}>
                                        <AccordProdTextInput headI={"כותרת הנלווה"} index={index} proIndex={proIndex} prodTxtVal={prodOpt.optionName}
                                            setProdItm={(index, proIndex, newValue) => changeProdOptionName(index, proIndex, OptINdex, newValue)} />

                                        <AccordProdTextInput headI={"טקסט על הכפתור"} index={index} proIndex={proIndex} prodTxtVal={prodOpt.optionBtnTxt}
                                            setProdItm={(index, proIndex, newValue) => changeProdOptionBtnTxt(index, proIndex, OptINdex, newValue)} />

                                        <AccordProdNumInput headI={"מקס' לבחירה"} minimum={0} index={index} proIndex={proIndex}
                                            prodTxtVal={prodOpt.maxSelection ? prodOpt.maxSelection : 0}
                                            setProdItm={(index, proIndex, newValue) => changeProdOptionMaxSelection(index, proIndex, OptINdex, newValue)} />


                                        <AccordProdNumInput headI={"נלווים ללא עלות"} minimum={0} index={index} proIndex={proIndex}
                                            prodTxtVal={prodOpt.freeAdds ? prodOpt.freeAdds : 0}
                                            setProdItm={(index, proIndex, newValue) => freeAdds(index, proIndex, OptINdex, newValue)} />


                                    </div>

                                    <div className={"col-md-6 mb-2 onRight"}>
                                        <div className={"input-group prodSwitch switchMe onRight"}>
                                            <label className={"spHead"}>בחירת חובה</label>
                                            <label className="ms-switch">
                                                <input type="checkbox" checked={prodOpt.mustSelect}
                                                    onChange={(e) => changeProdOptionMustSelect(index, proIndex, OptINdex, !prodOpt.mustSelect)} />
                                                <span className="ms-switch-slider ms-switch-primary round" />
                                            </label>
                                        </div>
                                        <div className={"input-group prodSwitch switchMe fullRow onRight"}>
                                            <label className={"spHead"}>בחירה מפוצלת</label>
                                            <label className="ms-switch">
                                                <input type="checkbox" checked={(prodOpt.splitSelection != null) ? prodOpt.splitSelection : false}
                                                    onChange={(e) => changeProdOptionSplitSelection(index, proIndex, OptINdex, !prodOpt.splitSelection)} />
                                                <span className="ms-switch-slider ms-switch-primary round" />
                                            </label>
                                            {(prodOpt.splitSelection) ? <div className={"inSetMe onRight"}>
                                                <label className="ms-checkbox-wrap">
                                                    <input type="checkbox" checked={(prodOpt.spSelQurts != null) ? prodOpt.spSelQurts : false}
                                                        onChange={(e) => changeProdOptionSplitSelectionQurters(index, proIndex, OptINdex, !prodOpt.spSelQurts)} />
                                                    <i className="ms-checkbox-check" />
                                                </label><span> &nbsp;אפשר גם לרבעים</span>
                                            </div> : ""}
                                        </div>

                                        <div className={"input-group prodSwitch switchMe onRight"}>
                                            <label className={"spHead"}>הצמדת רטבים</label>
                                            <label className="ms-switch">
                                                <input type="checkbox" checked={prodOpt.chkSauces}
                                                    onChange={(e) => changeChkSauces(index, proIndex, OptINdex, !prodOpt.chkSauces)} />
                                                <span className="ms-switch-slider ms-switch-primary round" />
                                            </label>

                                        </div>

                                        <div className={"input-group prodSwitch switchMe onRight"}>
                                            <label className={"spHead"}>בחירה מרובה</label>
                                            <label className="ms-switch">
                                                <input type="checkbox" checked={prodOpt.chkMoreThenOne}
                                                    onChange={(e) => changeChkMoreThenOne(index, proIndex, OptINdex, !prodOpt.chkMoreThenOne)} />
                                                <span className="ms-switch-slider ms-switch-primary round" />
                                            </label>
                                        </div>

                                        <div className={"input-group prodSwitch switchMe onRight"}>
                                            <label className={"spHead"}>2 בשורה</label>
                                            <label className="ms-switch">
                                                <input type="checkbox" checked={prodOpt.chkSideBySide}
                                                    onChange={(e) => changeChkSideBySide(index, proIndex, OptINdex, !prodOpt.chkSideBySide)} />
                                                <span className="ms-switch-slider ms-switch-primary round" />
                                            </label>
                                        </div>

                                    </div>
                                </>
                            }
                        </div>

                        <div className={"prodOptDetWrap"}>
                            <div className={"fullRow"}>
                                <div className={"onRight"} >
                                    <Button variant="contained" className={"addDivBtn"} onClick={() => createNewDetOption(index, proIndex, OptINdex)}
                                        startIcon={<AddBoxOutlined style={{ color: 'white' }} />}>
                                        הוסף אופציה
                                    </Button>
                                </div>

                                <label className={"onRight big spaceRight"}>אופציות הנלווה</label>

                            </div>

                            {
                                prodOpt.options?.map((optsDet, optsDetIndex) => {
                                    optis = (optsDetIndex === 0) ? "" : optis;
                                    optis = (optis === "") ? optsDet.servType : optis + " , " + optsDet.servType;
                                    return (
                                        <div key={optsDetIndex} className={`optsDet servTpDiv`}>
                                            <div className={"mnuItmOptType"}><label>שם האופציה</label>
                                                <input type="text" value={optsDet.servType} placeholder={"שם האופציה"}
                                                    className={`${(optsDet.instock != null && optsDet.instock != true) ? "notInstock" : ""}`}
                                                    onChange={(e) => changeProdOptDetName(index, proIndex, OptINdex, optsDetIndex, e.target.value)} />
                                            </div>
                                            <div className={"mnuItmOptPrice"}><label>תוספת מחיר</label><input type="text" value={optsDet.addToPrice} placeholder={"תוספת מחיר"}
                                                onChange={(e) => changeProdOptDetPrice(index, proIndex, OptINdex, optsDetIndex, e.target.value)} />
                                            </div>
                                            <div className={"mnuItmOptPrice"}><label>מקט לקופה</label><input type="text" value={optsDet.Makat} placeholder={"מקט לקופה"}
                                                onChange={(e) => changeProdOptDetMakat(index, proIndex, OptINdex, optsDetIndex, e.target.value)} />
                                            </div>
                                            {prodOpt.chkSauces ? <div className={"mnuItmOptSauces"}><label>רטבים</label><input type="text" value={optsDet.withSauces} placeholder={""}
                                                onChange={(e) => changeProdOptSauces(index, proIndex, OptINdex, optsDetIndex, e.target.value)} />
                                            </div> : ""}
                                            <div className={"divAsIconLeft pointing optidietDel"}
                                                onClick={() => removeDetOpti(index, proIndex, OptINdex, optsDetIndex)}><i className="far fa-trash-alt ms-text-danger" /></div>

                                        </div>

                                    )
                                })
                            }

                        </div>
                    </div>
                </Card.Body>
            </Accordion.Collapse>
            <Accordion.Toggle as={Card.Header} eventKey={props.addsCounter} className={`${prodOpt.isPopOption ? "isPopOption" : ""}`}>
                <span>{!prodOpt.isPopOption ? prodOpt.optionName : prodOpt.optionName + " (" + prodOpt.itemsEffected + ") "}</span><span className={"noBoldvSmall"}>{optis}</span>
            </Accordion.Toggle>

            <div className={"stpBtns"}>
                <div className={"acoordBtns"}>
                    <div className={"acoordIcon acord1 pointing"} onClick={() => removeOpti(index, proIndex, OptINdex)}><i className="far fa-trash-alt theSecIconsColor onLeft" /></div>
                    {OptINdex != 0 && props.prodsOptLength > 1 ? <div className={"acoordIcon acord2 pointing"} onClick={() => setUpOpti(index, proIndex, OptINdex)} title={"הקפץ למעלה"}><i className="far fa-caret-square-up theSecIconsColor onLeft" /></div> : ""}
                    {OptINdex < props.prodsOptLength - 1 ? <div className={"acoordIcon acord3 pointing"} onClick={() => setDownOpti(index, proIndex, OptINdex)} title={"הקפץ למטה"}><i className="far fa-caret-square-down theSecIconsColor onLeft" /></div> : ""}
                </div>
                <div className={"saveToSilo"}>
                    <div className={"acoordIcon acordbt pointing"} onClick={() => saveToSilo(index, proIndex, OptINdex)} title={"שמור למאגר"}><i className="far fa-save theSecIconsColor onLeft" /></div>
                </div>
            </div>

            {thePopUp}
        </Card >
    )
}

export default AccordProdOptions;